.App {
    text-align: center;
  }

.App-Footer-Container {
  text-align: center;
  background-color: "black";
}  
.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: black;
}

.NavBar {
  padding-bottom:10px;
  min-height:5vh ;
}

.nav-link{
  border-radius: 4px;
  padding:5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left : 10px;
  padding-right: 10px;
  color:black;
  margin:10px 3px;
  text-align: center;
  font-family: 'Sofia-Pro';
  text-decoration : none;
}

.nav-link-title{
  border-radius: 4px;
  padding:5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left : 10px;
  padding-right: 10px;
  color:black;
  margin:10px 3px;
  text-align: center;
  font-family: 'Sofia-Pro';
  text-decoration : none;
}

.nav-link-icon{
  border-radius: 4px;
  padding:5px;
  padding-left : 10px;
  padding-right: 10px;
  color:black;
  margin:10px 3px;
  text-align: center;
  text-decoration : none;
}

.nav-link:hover{
  background-color: #cbd2d0;
  
}

.nav-link-icon:hover{
  background-color: #cbd2d0;
  
}

.nav-link-title:hover{
  background-color: #cbd2d0;
  
}